import React from 'react';
import { Box, Text, Heading, Image, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Page';
import WhyWeBoxitLayout from './_layout';
import { Hide } from '../../components/hide';
import { Container } from '../../components/container';

import {
  ForCustomersBullets,
  ForCustomersBulletsMobile,
} from './for_customers_shared';

import DeliveryWomanImage from '../../images/delivery-1@2x.jpg';
import DeliveryIphoneImage from '../../images/delivery-2@2x.png';

export const BoxContentTitle = ({ children }) => (
  <Text
    py={['20px', '15px', '20px']}
    color="blues.twilight"
    fontSize={['18px', '20px', '25px']}
    lineHeight="1.2"
    letterSpacing={[0, '0.2px']}
  >
    {children}
  </Text>
);

export const BoxContentListItem = ({ children }) => (
  <Box pb={['6px', '4px', '10px']}>
    <li>{children}</li>
  </Box>
);

export const BoxContentList = ({ children }) => (
  <Box mt="22px" ml={[0, '10%', '10%']}>
    <Box
      color="#456b8c"
      fontSize={['11px', '12px', '14px']}
      lineHeight="2"
      textAlign="left"
    >
      <ul>{children}</ul>
    </Box>
  </Box>
);

const BoxContent = () => {
  const { t } = useTranslation();

  return (
    <Box
      marginTop={['22px', '45px', '56px']}
      color="marine"
      fontSize={['11px', '12px', '15px']}
      fontWeight="300"
      lineHeight="2"
      letterSpacing={[0, '0.7px']}
    >
      <Flex>
        <Image
          width={['0', '280px', '350px']}
          height={['0', '320px', '400px']}
          marginRight={['0', '30px']}
          src={DeliveryWomanImage}
          alt="Smart Delivery Image 1"
          backgroundSize="cover"
        />
        <Box>
          <Heading
            width={[1, 0.9, 0.8]}
            fontSize={['22x', '32px', '40px']}
            lineHeight={[1.1]}
            letterSpacing={[0]}
            color="blues.peacock"
          >
            {t('why_we_boxit.smartest_box_delivery.header')}
          </Heading>
          <BoxContentTitle>
            {t('why_we_boxit.smartest_box_delivery.paragraphs.0.title')}
          </BoxContentTitle>
          {t('why_we_boxit.smartest_box_delivery.paragraphs.0.content')}
        </Box>
      </Flex>
      <Flex pt={['10px', '50px', '64px']}>
        <Box>
          <BoxContentTitle>
            {t('why_we_boxit.smartest_box_delivery.paragraphs.1.title')}
          </BoxContentTitle>
          {t('why_we_boxit.smartest_box_delivery.paragraphs.1.content')}
          <BoxContentList>
            {t('why_we_boxit.smartest_box_delivery.paragraphs.1.bullets', {
              returnObjects: true,
            }).map((bullet, index) => (
              <BoxContentListItem key={'bullet' + (index + 1)}>
                {bullet}
              </BoxContentListItem>
            ))}
          </BoxContentList>
        </Box>
        <Box
          marginLeft={['0', '30px']}
          width={['0', '280px', '350px']}
          height={['0', '391px', '488px']}
        >
          <Box
            marginTop={['0', '16px', '22px']}
            width={['0', '280px', '350px']}
            height={['0', '327px', '409px']}
            backgroundColor="blues.veryLight"
            css={{ borderRadius: '20px' }}
          />
          <Image
            marginLeft={['0', '42px', '52px']}
            marginTop={['0', '-346px', '-433px']}
            width={['0', '197px', '246px']}
            height={['0', '391px', '488px']}
            src={DeliveryIphoneImage}
            alt="Smart Delivery Image 2"
            backgroundSize="contain"
            css={{ position: 'relative' }}
          />
        </Box>
      </Flex>
    </Box>
  );
};

const SmartestBoxDelivey = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <WhyWeBoxitLayout
      subtitle={t('why_we_boxit.smartest_box_delivery.menu.title')}
      {...props}
    >
      <Page
        title={t('why_we_boxit.smartest_box_delivery.page.title')}
        subtitle={t('why_we_boxit.smartest_box_delivery.page.subtitle')}
      ></Page>

      <Container>
        {/* Mobile Only */}
        <Hide tablet desktop>
          <BoxContent />
          <ForCustomersBulletsMobile />
        </Hide>
        {/* Tablet & Desktop */}
        <Hide mobile>
          <BoxContent />
          <ForCustomersBullets />
        </Hide>
      </Container>
    </WhyWeBoxitLayout>
  );
};

export default SmartestBoxDelivey;
