import React from 'react';
import Swiper from 'react-id-swiper';
import { swiperParams } from '../../theme';
import { Box, Text, Flex, Image } from 'rebass';
import { Container } from '../../components/container';
import { useTranslation } from 'react-i18next';

import ForCustomersYellowTriangleSmall from '../../icons/for-customers-yellow-triangle-small.svg';

const ForCustomersDetails = [
  {
    title: 'why_we_boxit.for_customers.sections.services.title',
    subtitle: 'why_we_boxit.for_customers.sections.services.subtitle',
    bullets: 'why_we_boxit.for_customers.sections.services.bullets',
  },

  {
    title: 'why_we_boxit.for_customers.sections.packaging.title',
    subtitle: 'why_we_boxit.for_customers.sections.packaging.subtitle',
    bullets: 'why_we_boxit.for_customers.sections.packaging.bullets',
  },

  {
    title: 'why_we_boxit.for_customers.sections.network.title',
    subtitle: 'why_we_boxit.for_customers.sections.network.subtitle',
    bullets: 'why_we_boxit.for_customers.sections.network.bullets',
  },

  {
    title: 'why_we_boxit.for_customers.sections.insurance.title',
    subtitle: 'why_we_boxit.for_customers.sections.insurance.subtitle',
    bullets: 'why_we_boxit.for_customers.sections.insurance.bullets',
  },
];

const ForCustomersBulletsTitle = ({ children }) => (
  <Flex width={1} flexDirection="row">
    <Image
      src={ForCustomersYellowTriangleSmall}
      alt="Background Image"
      backgroundSize="cover"
      backgroundPosition="left center"
    />
    <Text
      my="auto"
      pt={['8px', 0]}
      pl={['10px', '10px', 4]}
      color="blues.peacock"
      fontSize={['18px', '16px', '26px']}
      fontWeight="bold"
      lineHeight={['1.71', '1.28', '0.88']}
      letterSpacing={['0.82px', '0.82px', '1.18px']}
      textAlign="left"
    >
      {children}
    </Text>
  </Flex>
);

const ForCustomersBulletsSubtitle = ({ children }) => (
  <Text
    pt={['14px', 4, '20px']}
    pb={['10px', 4, '35px']}
    pr={[0, 3, 4]}
    color="blues.peacock"
    fontSize={['14px', '14px', '18px']}
    fontWeight="normal"
    lineHeight={['1.71', '1.71', '1.5']}
    textAlign="left"
  >
    {children}
  </Text>
);

const ForCustomersBulletsList = ({ children }) => (
  <Box
    ml={[0, '-17px', '-25px']}
    pr={[0, 2, 3]}
    color="#456b8c"
    fontSize={['12px', '12px', '14px']}
    // lineHeight={['1.33', '1.33', '3.14']}
    letterSpacing={['-0.6px', '-0.6px', '1.18px']}
    textAlign="left"
    css={{ opacity: '0.88' }}
  >
    <ul>
      {children &&
        children.map((bullet, index) => (
          <li key={'bullet' + (index + 1)}>
            <Box pb={['5px', '10px', '10px']}>{bullet}</Box>
          </li>
        ))}
    </ul>
  </Box>
);

export const ForCustomersBullets = () => {
  const { t } = useTranslation();

  return (
    <Flex
      pt={[3, 5, 6]}
      pb={[3, 0, 5]}
      flexWrap="nowrap"
      flexDirection="row"
      css={{ overflowX: 'scroll' }}
    >
      {ForCustomersDetails &&
        ForCustomersDetails.map((detail, index) => (
          <div key={'forCustomerDetails' + index}>
            <Flex pr={[4, 3, 2]} width="1" flexDirection="column">
              <ForCustomersBulletsTitle>
                {t(detail.title)}
              </ForCustomersBulletsTitle>
              <ForCustomersBulletsSubtitle>
                {t(detail.subtitle)}
              </ForCustomersBulletsSubtitle>
              <ForCustomersBulletsList>
                {t(detail.bullets, { returnObjects: true })}
              </ForCustomersBulletsList>
            </Flex>
          </div>
        ))}
    </Flex>
  );
};

export const ForCustomersBulletsMobile = ({ px }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Swiper {...swiperParams}>
        {ForCustomersDetails &&
          ForCustomersDetails.map((detail, index) => (
            <div key={'forCustomerDetailsMobile' + index}>
              <Box px={px ? px : 5} pt="31px" pb="23px" mx="auto">
                <ForCustomersBulletsTitle>
                  {t(detail.title)}
                </ForCustomersBulletsTitle>
                <ForCustomersBulletsSubtitle>
                  {t(detail.subtitle)}
                </ForCustomersBulletsSubtitle>

                <ForCustomersBulletsList>
                  {/* {t(detail.bullets, { returnObject: true })} */}
                </ForCustomersBulletsList>
              </Box>
            </div>
          ))}
      </Swiper>
    </Container>
  );
};

export const BoxContentTitle = ({ children }) => (
  <Text
    pb={['20px', '15px', '20px']}
    color="blues.twilight"
    fontSize={['24px', '32px', '46px']}
    fontWeight="300"
    lineHeight="0.54"
    letterSpacing={[0, '-1.7px']}
  >
    {children}
  </Text>
);

export default ForCustomersBullets;
