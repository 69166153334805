import React from 'react';

import SEO from '../../components/seo';
import { Layout } from '../../layout';
import { HeaderCompact, NavLink } from '../../components/Header';
import { useTranslation } from 'react-i18next';

const WhyWeBoxitLayout = ({ subtitle, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Layout {...props}>
      <SEO
        title={t('why_we_boxit.submenu.title')}
        keywords={['weboxit', 'boxit']}
      />
      <HeaderCompact
        title={t('why_we_boxit.submenu.title')}
        subtitle={subtitle}
      >
        <NavLink
          to="/why_weboxit/4_steps_tutorial"
          activeClassName="header-active-link"
        >
          {t('why_we_boxit.submenu.4_steps_tutorial')}
        </NavLink>
        {/* <NavLink
          to="/why_weboxit/smartest_box_delivery"
          activeClassName="header-active-link"
        >
          {t('why_we_boxit.submenu.smartest_box_delivery')}
        </NavLink> */}
        <NavLink
          to="/why_weboxit/for_customers"
          activeClassName="header-active-link"
        >
          {t('why_we_boxit.submenu.for_customers')}
        </NavLink>
        <NavLink
          to="/why_weboxit/for_eshops_and_businesses"
          activeClassName="header-active-link"
        >
          {t('why_we_boxit.submenu.for_eshops_and_businesses')}
        </NavLink>
      </HeaderCompact>
      {children}
    </Layout>
  );
};

export default WhyWeBoxitLayout;
